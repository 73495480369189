import { DSRRequestDetails, FetchDsrDataSourcesParams } from './requestDetailsSlice'
import { DataSource } from '../../../services/api/apiTypes'
import { getAfterCursor, parameterizeArrayofObjects } from '../../../utils/graphqlUtil'
import { DSR_DATASOURCES_LIMIT_DEFAULT } from '../../../constants'
import { gql } from 'graphql-request'

export const queryDSRRequestDetails = (dsrId: string): string => {
  return gql`
  {
    dsrDetails(dsrId: ${dsrId}) {
      id
      requestType
      admin {
        id
        firstName
        lastName
        email
      }
      location
      lastUpdate
      dataSubject {
        id
        firstName
        lastName
        email
        residency
        address
        phoneNumber
        uniqueID
        dob
      }
      dueDate
      assignee
      idVerification {
        verificationEmailSentOn
        verificationCompletedOn
        idVerificationStatus
        approvedBy {
          id
          firstName
          lastName
          email
        }
        approvedOn
        attachments {
          id
          name
          type
        }
      }
    }
  }
`
}

export const mapQueryRequestDetails = (raw: any): DSRRequestDetails => {
  return raw
}

export const queryDsrDataSourcesList = ({
  filters,
  entityId
}: FetchDsrDataSourcesParams): string => {
  let commonParamString = ''
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  const count360Query = entityId
    ? `attributeInstance(entityId: "${entityId}"${commonParamString}){
    count
  }`
    : ''

  return gql`
    {
   dsrSetting{
    edges{
      node{
      defaultDsrDatasource(first: 999) {
        count
        edges {
          node {
            id
            name
            type
            createdBy
            ${count360Query}
          }
        }
      }
    }
    }
    }
    }
  `
}

export const mapQueryDsrDataSourcesList = (raw: any): { list: DataSource[]; total: number } => {
  const defaultdatasourcesList = raw.dsrSetting?.edges[0].node.defaultDsrDatasource

  const datasourcesList = defaultdatasourcesList?.edges?.map((edge) => {
    const ds = edge.node
    return {
      id: ds.id,
      createdBy: ds.createdBy,
      dataSourceType: ds.type || '',
      dataSourceName: ds.name || '',
      instancesDetectedCount: ds.attributeInstance?.count || 0
    }
  })

  return {
    list: datasourcesList,
    total: defaultdatasourcesList.count || 0
  }
}

export const queryDefaultDataSources = ({
  filters,
  entityId,
  page,
  dsrRequestId,
  dsrDataSourceFilters,
  searchQuery
}: FetchDsrDataSourcesParams) => {
  let commonParamString = ''
  const filterString = parameterizeArrayofObjects(filters?.filter || [])
  if (filterString) {
    commonParamString += `,filter:${filterString}`
  }
  const count360Query = entityId
    ? `attributeInstance(entityId: "${entityId}"${commonParamString}){
    count
  }`
    : ''

  const cursor = getAfterCursor(page || 1, DSR_DATASOURCES_LIMIT_DEFAULT)
  return gql`
    {
      dsrSetting(
        dsrRequestId: "${dsrRequestId}"
        ${
          dsrDataSourceFilters?.attributeDetected !== undefined
            ? `attributeDetected: ${dsrDataSourceFilters?.attributeDetected}`
            : ''
        }
        ${
          dsrDataSourceFilters?.attributeValidated !== undefined
            ? `attributeValidated: ${dsrDataSourceFilters?.attributeValidated}`
            : ''
        }
        ${
          dsrDataSourceFilters?.assignee
            ? `assignee: ${JSON.stringify(dsrDataSourceFilters?.assignee)}`
            : ''
        }
        ${
          dsrDataSourceFilters?.dataValidationStatus
            ? `dataValidationStatus: ${JSON.stringify(dsrDataSourceFilters?.dataValidationStatus)}`
            : ''
        }
      ) {
        edges {
          node {
            defaultDsrDatasource(first: ${DSR_DATASOURCES_LIMIT_DEFAULT}, after: "${cursor}" ${
    searchQuery ? `, searchName: "${searchQuery}"` : ``
  }) {
              count
              edges {
                node {
                  id
                  name
                  type
                  createdBy
                  ${count360Query}
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDsrDefaultDataSources = (
  raw: any
): { defaultDatasources: DataSource[]; defaultDatasourcesCount: number } => {
  const defaultdatasourcesList = raw.dsrSetting?.edges[0].node.defaultDsrDatasource

  const datasourcesList = defaultdatasourcesList?.edges?.map((edge) => {
    const ds = edge.node
    return {
      id: ds.id,
      createdBy: ds.createdBy,
      dataSourceType: ds.type || '',
      dataSourceName: ds.name || '',
      instancesDetectedCount: ds.attributeInstance?.count || 0
    }
  })

  return {
    defaultDatasources: datasourcesList,
    defaultDatasourcesCount: defaultdatasourcesList.count || 0
  }
}
